/** ------------------------------
    Tooltip
    ------------------------------ */
.tooltip {
    border-radius: .2em;
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    font-size: .9em;

    &:not(.is-active) {
        display: none;
        }

    }
    .tooltip__body {
        padding: .4em .8em;
        }
        .tooltip__data-item {
            &::before {
                content: "";
                display: inline-block;
                margin-right: 4px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                border: 1px solid var(--brand-pink);
                background-color: #fff;
                vertical-align: middle;
                }
            }
