/** ------------------------------
    Data list
    ------------------------------ */
.data-list {
    display: flex;
    flex-wrap: wrap;

    & :matches(dt, dd) {
        padding: 15px 0;
        border-bottom: 1px solid #f1f1f1;
        }

    & dt {
        width: 25%;
        color: var(--brand-slate);
        }

    & dd {
        margin-left: 0;
        width: 75%;
        }
    }
