/** ------------------------------
    Development Matrix Key
    ------------------------------ */

.skill-level-key {
    display: flex;
    height: 40px;
}

.skill-level-key div {
    outline: 1px solid black;
    margin-top: 1px;
    margin-left: 1px;
    flex-basis: 16.667%;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 10px;
}

@media only screen and (max-width: 400px) {
    .skill-level-key div {
        padding-left: 0px;
    }
}

/** ------------------------------
    Skills Matrix
    ------------------------------ */

.state-0,
[state="0%"] {
    background-color: white !important;
}

.state-20,
[state="20%"] {
    background-color: #d3dbe5 !important;
    color: black;
}

.state-40,
[state="40%"] {
    background-color: #acb8ca !important;
}

.state-0 span,
[state="0%"] span,
.state-20 span,
[state="20%"] span,
.state-40 span,
[state="40%"] span {
    color: black;
}

.state-60,
[state="60%"] {
    background-color: #677b89 !important;
}

.state-80,
[state="80%"] {
    background-color: #213d47 !important;
}

.state-100,
[state="100%"] {
    background-color: #ff006e !important;
}

.state-60 span,
[state="60%"] span,
.state-80 span,
[state="80%"] span,
.state-100 span,
[state="100%"] span {
    color: white;
}
