/** ------------------------------
    Data legend
    ------------------------------ */
.data-legend {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    }
    .data-legend__title {
        margin-right: 20px;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        text-transform: uppercase;
        }
    .data-legend__key {
        @mixin list-reset;
        display: flex;
        align-items: center;
        }
        .data-legend__key-item {
            display: flex;
            align-items: center;
            color: var(--brand-slate);
            cursor: help;

            &:not(:last-child) {
                margin-right: 20px;
                }
            }
        .data-legend__key-icon {
            display: block;
            margin-right: 8px   ;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            vertical-align: middle;
            }
