/** ------------------------------
    Alignments
    ------------------------------ */
@define-mixin center-block {
    margin-left: auto;
    margin-right: auto;
}

.center-block {
    @mixin center-block;
    }

@define-mixin align $alignment, $force: false {
    @if $force == true {
        text-align: $alignment !important;
    } @else {
        text-align: $alignment;
    }
}

@define-mixin pull $alignment, $force: false {
    @if $force == true {
        float: $alignment !important;
    } @else {
        float: $alignment;
    }
}

.align-center {
    @mixin align center, true;
    }

.align-left {
    @mixin align left, true;
    }

.align-right {
    @mixin align right, true;
    }

.pull-left {
    @mixin pull left, true;
    }

.pull-right {
    @mixin pull right, true;
    }

.pull-none {
    @mixin pull none, true;
    }


/** ------------------------------
    Layout
    ------------------------------ */
.no-margin {
    margin: 0 !important;
    }

.no-padding {
    padding: 0 !important;
    }


/** ------------------------------
    Visibility
    ------------------------------ */
.hidden {
    display: none;
    }

@define-mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.visually-hidden {
    @mixin visually-hidden;
    }


/** ------------------------------
    Colours
    ------------------------------ */
.brand-pink {
    color: var(--brand-pink);
    }

.brand-blue {
    color: var(--brand-blue);
    }

.brand-slate {
    color: var(--brand-slate);
    }

.brand-grey {
    color: var(--brand-grey);
    }

.brand-light {
    color: var(--brand-light);
    }

.bg-brand-pink {
    background-color: var(--brand-pink);
    color: #fff;
    }

.bg-brand-blue {
    background-color: var(--brand-blue);
    color: #fff;
    }

.bg-brand-slate {
    background-color: var(--brand-slate);
    }

.bg-brand-grey {
    background-color: var(--brand-grey);
    }

.bg-brand-light {
    background-color: var(--brand-light);
    }

.bg-white {
    background-color: #fff;
    }
