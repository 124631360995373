/** ------------------------------
    Table
    ------------------------------ */
.table-responsive {
    overflow-x: auto;
}

.table {
    & thead {
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        background-color: var(--brand-light);
    }

    & tbody > tr {
        border-top: 1px solid #f1f1f1;

        /* Selected */
        &:matches(.selected) {
            background-color: rgba(255, 203, 124, 0.12) !important;
        }
    }

    & th {
        padding: 10px;
        @mixin font-scale 12;
        font-weight: 400;
        color: var(--brand-slate);
        text-align: left;
        text-transform: uppercase;
        white-space: nowrap;
    }

    & td {
        padding: 20px 10px;
    }

    /* Remove styles on abbreviations */
    & abbr {
        text-decoration: none;
    }

    & :matches(th, td) {
        /* First and last child on each row match padding on .card */
        &:first-child {
            padding-left: 25px;
        }
        &:last-child {
            width: 1px;
            padding-right: 25px;
        }
    }

    & .collapse {
        width: 1%;
        white-space: nowrap;
    }
}

/** ------------------------------
    Modifiers
    ------------------------------ */
.table--hover {
    & tbody > tr {
        transition: background-color 0.3s ease;

        /* Hover, ignoring .disabled rows */
        &:not(.disabled):hover {
            background-color: rgba(255, 203, 124, 0.15);
            cursor: pointer;
        }
    }
}

.hours-table-activity-column {
    min-width: 270px;
}

.hours-table-reflections-column {
    min-width: 300px;
}

.reveal-more-anchor-tag {
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .hours-table-activity-column {
        min-width: 200px;
    }
}

@media only screen and (max-width: 480px) {
    .hours-table-activity-column {
        min-width: unset;
    }
}
