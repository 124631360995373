/** ------------------------------
    Help
    ------------------------------ */
.help {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background-color: var(--brand-pink);
    font-size: 80%;
    color: #fff;
    text-align: center;
    line-height: 1.5em;
    }
