/** ------------------------------
    Datepicker customisation
    ------------------------------ */
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border-color: var(--brand-pink) !important;
    }
.vdp-datepicker__calendar .cell.selected,
.vdp-datepicker__calendar .cell.selected:hover,
.vdp-datepicker__calendar .cell.selected.highlighted {
    background-color: var(--brand-pink) !important;
    color: #fff !important;
    }
