/** ------------------------------
    Dashboard
    ------------------------------ */
.news-list {
    list-style: none;
    margin: 0;
    padding-left: 0;

    & > :not(:first-child) {
        margin-top: 1rem;
        }

    & a {
        color: var(--brand-pink);
        }
    }
