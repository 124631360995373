.slider-component .slidecontainer {
    width: 100%;
}

.slider-component .slidecontainer .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: #c2c2c2;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider-component .slidecontainer .slider:hover {
    opacity: 1;
}

.slider-component .slidecontainer .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: #0000ff;
    cursor: pointer;
    border-radius: 50%;
}

.slider-component .slidecontainer .slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: #0000ff;
    cursor: pointer;
    border-radius: 50%;
}
