/** ------------------------------
    Card grid
    ------------------------------ */
.card-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    & .card {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.activity-card-grid {
    /* justify-content: center;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 150px 150px 150px; */
    grid-gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
}

/** ------------------------------
    Card tabs
    ------------------------------ */
.card-tabs {
    /* Remove border-radius from cards */
    & .card {
        border-top-left-radius: 0;
        border-top: 0;
    }
}
.card-tabs__nav {
    display: inline-flex;
}
.card-tabs__nav-item {
    & a {
        display: block;
        padding: 15px 25px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: rgba(0, 0, 0, 0.05);
        text-decoration: none;
        opacity: 0.7;

        &.router-link-exact-active {
            border: 1px solid #f5f6f8;
            border-bottom: 0;
            background-color: #fff;
            opacity: 1;
        }
    }
}

/** ------------------------------
    Card
    ------------------------------ */
.card {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #f5f6f8;
    background-color: #fff;

    &:matches(a) {
        color: currentcolor;
        text-decoration: none;
    }
}

/* Header */
.card__header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 25px 25px 0;
}
.card__title {
    @mixin font-scale 16;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.4;

    &:only-child {
        flex-grow: 1;
    }
}
.card__title--with-separator {
    &::after {
        content: "";
        display: inline-block;
        margin: 0 1.5em;
        width: 1px;
        height: 1.5em;
        background-color: var(--brand-grey);
        vertical-align: middle;
    }
}
.card__title-stat {
    @mixin font-scale 28;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px 25px;
}
.card__actions {
    display: flex;
    align-items: center;
    margin-top: -5px;
    margin-bottom: -5px;
    margin-left: auto; /* Align right */

    & > :not(:last-child) {
        margin-right: 10px;
    }
}

/* Body */
.card__body {
    overflow: hidden;
    padding: 25px;
    flex-grow: 1;

    & > :last-child {
        margin-bottom: 0;
    }

    & > :matches(.table-responsive, .table) {
        margin: 0 -25px -25px;
    }
}

.card__body a {
    color: #ff006e;
}

.card__body--flush {
    /* padding: 25px 0 0; */
}

/* Footer */
.card__footer {
    margin-top: auto;
    padding: 15px 25px;
    border-top: 1px solid #f5f6f8;
    background-color: var(--brand-light);
}

/** ------------------------------
    Content
    ------------------------------ */
.card__icon {
    margin: 10px 0 30px;

    & > svg {
        fill: var(--brand-blue);
        width: 105px;
        height: 105px;
    }
}

.card__chart {
    --chart-nudge: 15px;

    position: relative;
    top: calc(var(--chart-nudge) * -1);
    height: calc(100% + var(--chart-nudge));
    min-height: 200px;
}
.card__chart-svg {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(100% + 75px);
    height: 100%;
    transform: translate3d(-50%, 0, 0);

    & .active-line {
        stroke: rgba(0, 0, 0, 0.2);
        stroke-with: 1;
    }

    & .stroke {
        stroke: var(--brand-pink);
        stroke-width: 2;
    }

    & .point {
        fill: #fff;
        stroke: var(--brand-pink);
        stroke-width: 2;
        r: 5;
    }

    & .fill {
        fill: url(#chartFill);
        fill-opacity: 0.2;
    }

    & .label {
        font-weight: 400;
        opacity: 0.4;

        & > line {
            display: none;
        }
    }
}

.card__description {
    font-style: italic;
}

.activity-card {
    flex: 0 0 32%;
    padding: 20px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #bcc6d6;
    background-color: #fff;
    text-align: center;
    min-height: 108px;
}

.subactivity-card {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #bcc6d6;
    background-color: #fff;
}

.subactivity-card a {
    text-decoration: none;
}

/** ------------------------------
    Modifiers
    ------------------------------ */

@media (width >= 640px) {
    .card--25\% {
        width: calc(50% - 20px);
    }
    .card--50\% {
        width: calc(100% - 20px);
    }
    .card--75\% {
        width: 100%;
    }
}

@media (width >= 1024px) {
    .card--25\% {
        width: calc(25% - 20px);
    }
    .card--50\% {
        width: calc(50% - 20px);
    }
    .card--75\% {
        width: calc(75% - 20px);
    }
}

@media only screen and (max-width: 600px) {
    .activity-card-grid {
        flex-direction: column;
    }
    .activity-card {
        min-height: unset;
    }

    #hours .card__actions {
        display: block;
    }

    #hours .card__header {
        align-items: baseline;
    }

    .button-wrap-outer {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .button-wrap-inner {
        flex-wrap: wrap;
        justify-content: end;
    }

    .button_space {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .card__header {
        flex-direction: column;
        align-items: start;
        padding: 25px 25px 0;
    }

    .card__actions {
        margin-left: unset;
        margin-top: 15px;
    }

    .role-card__actions {
        justify-content: space-between;
        flex-direction: row-reverse;
    }
}

.card--hover {
    transition: transform 0.2s ease;
    cursor: pointer;

    &:hover {
        box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.1);
        transform: scale(1.01);
    }
}
