/** ------------------------------
    Progress Stat
    ------------------------------ */
.progress-stat-group {
    margin: 0 -10px;

    & > :not(:last-child) {
        margin-bottom: 10px;
        }

    }

.progress-stat {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    }
    .progress-stat__label {
        @mixin font-scale 12;
        width: 100%;
        text-transform: uppercase;
        color: var(--brand-slate);
        }
    .progress-stat__bar {
        overflow: hidden;
        height: 10px;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, .1);
        width: calc(100% - 60px);

        & > * {
            width: 0%;
            height: 100%;
            border-radius: 3px;
            transition: width .5s ease .5s;
            }
        }
    .progress-stat__figure {
        @mixin font-scale 20;
        margin-top: -.2em;
        margin-bottom: -.2em;
        }
