/** ------------------------------
    Data Filter
    ------------------------------ */
.data-filter {
    position: relative;
    }
    .data-filter__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        padding: 15px;
        width: 300px;
        border-radius: 4px;
        border: 1px solid #f5f6f8;
        background-color: #fff;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, .1);

        & > :last-child {
            margin-bottom: 0;
            }

        &::before {
            content: "";
            position: absolute;
            top: -5px;
            right: 15px;
            width: 10px;
            height: 10px;
            border-top: 1px solid #f5f6f8;
            border-left: 1px solid #f5f6f8;
            background-color: #fff;
            transform: rotate(45deg);
            }
        }
