/** ------------------------------
    Media object
    ------------------------------ */
.media-object {
    display: flex;
    }
    .media-object__media {
        flex: 0 0 120px;
        }
    .media-object__body {
        flex-grow: 1;
        padding-left: 1rem;
        }
        .media-object__title {
            margin-bottom: .5em;
            }
