/** ------------------------------
    Export
    ------------------------------ */
body.export {
    min-height: 100vh;
    background-color: #fff;

    & h1 {
        font-size: 2rem;
        }

    & h2 {
        font-size: 1.25em;
        }

    & h3 {
        margin-bottom: .25em;
        font-size: 1em;
        }

    & .table {

        & th {
            padding: 10px;
            }

        & td {
            padding: 10px;
            }

        & :matches(th, td) {

            /* First and last child on each row match padding on .card */
            &:first-child {
                padding-left: 10px;
                }
            &:last-child {
                padding-right: 10px;
                }

            }

        }
    }

/* Pages */
.export-page {
    page-break-before: always;
    }

/* Page header */
.export-header {
    position: relative;
    margin-bottom: 3rem;
    }
    .export-header__logo {
        position: absolute;
        top: 0;
        right: 0;
        }
    .export-header__title {
        margin-bottom: 1em;
        }
    .export-header__user-info {
        @mixin list-reset;
        }
