/** ------------------------------
    Box sizing
    ------------------------------ */
html {
    box-sizing: border-box;
    }

*,
*::before,
*::after {
    box-sizing: inherit;
    }


/** ------------------------------
    Containers
    ------------------------------ */
@define-mixin container-gutters {
    padding: 0 calc(var(--container-gutter) / 2);
}

.container {
    margin: auto;
    @mixin container-gutters;
    max-width: var(--container-width);
    }

.container--fluid {
    max-width: none;
    }

.container--md {
    max-width: calc(var(--container-width) * .8);
    }

.container--sm {
    max-width: calc(var(--container-width) * .66);
    }


/** ------------------------------
    App
    ------------------------------ */
#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    }

.content-container {
    padding: 50px 0;
    }
