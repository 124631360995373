/** ------------------------------
    Validation
    ------------------------------ */
.validation {
    padding-bottom: 40px;
    }
    .validation__header {
        margin-bottom: 3rem;
        display: flex;
        justify-content: space-between;
        }


/** ------------------------------
    Table
    ------------------------------ */
.validation-table {

    & tbody {

        &:not(:first-child) {
            border-top: 1px solid #f1f1f1;
            }

        & tr {
            border-top: 0;

            &:first-child:not(.disabled) {

                &:matches(:hover, :hover ~ tr) {
                    background-color: rgba(255, 203, 124, .1);
                    cursor: pointer;
                    }

                }
            }

        & td > :last-child {
            margin-bottom: 0;
            }

        }

    }


/** ------------------------------
    State control/icon
    ------------------------------ */
.validation-state-control {
    display: inline-flex;

    & input {
        position: absolute;
        visibility: hidden;
        z-index: -1;

        &:checked {

            & ~ .validation-state-control__unchecked {
                display: none;
                }

            & ~ .validation-state-control__checked {
                display: block;
                }

            }
        }

    }
   .validation-state-control__checked {
        display: none;
        }

.validation-state-control--checked {

    & .validation-state-control__unchecked {
        display: none;
        }

    & .validation-state-control__checked {
        display: block;
        }

    }
