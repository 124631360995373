/** ------------------------------
    Choice (checkbox/radio)
    ------------------------------ */
.custom-choice-control {
    margin: var(--form-control-margin) 0;
    display: inline-flex;
    align-items: center;

    & input {
        visibility: hidden;
        position: absolute;
        z-index: -1;
    }

    & label {
        display: flex;
        align-items: center;
        font-weight: 300 !important;
        color: var(--brand-slate);

        &::before {
            content: "";
            display: inline-block;
            width: 1.25em;
            height: 1.25em;
            margin-right: 0.5em;
            border-radius: var(--form-control-border-radius);
            border: var(--form-control-border-width) solid
                var(--form-control-default-border-color);
            transition: background-color 0.2s ease;
        }

        /* Remove right margin if label is empty */
        &:empty::before {
            margin-right: 0;
        }
    }

    /* Radio */
    & input[type="radio"] {
        & ~ label::before {
            border-radius: 50%;
        }
    }

    /* Checked */
    & input:checked {
        & ~ label::before {
            background: var(--brand-slate);
            box-shadow: inset 0 0 0 0.15em #fff;
        }
    }

    /* Disabled */
    & input:disabled {
        & ~ label {
            pointer-events: none;
        }

        & ~ label::before {
            opacity: 0.7;
        }

        &:checked ~ label::before {
            background: var(--form-control-default-border-color);
        }
    }
}

/** ------------------------------
    Select
    ------------------------------ */
.custom-select-control {
    position: relative;
    margin: var(--form-control-margin) 0;
    border: var(--form-control-border-width) solid
        var(--form-control-default-border-color);
    border-radius: var(--form-control-border-radius);
    background-color: var(--form-control-default-bg);
    background-image: linear-gradient(#fff, #f4f4f4);

    &::after {
        content: "";
        position: absolute;
        top: 13px;
        right: 14px;
        width: 8px;
        height: 8px;
        background-image: url("../../img/inline/select-arrow.svg");
        background-repeat: no-repeat;
        background-size: 8px auto;
    }

    &:focus-within {
        border-color: var(--form-control-default-border-color-active);
    }

    & > select {
        @mixin form-control;
        padding-right: 40px;
        margin: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;

        &:focus {
            outline: 0;
        }

        &::-ms-expand {
            display: none;
        }
    }
}

/** ------------------------------
    File
    ------------------------------ */
.custom-file-control {
    margin: var(--form-control-margin) 0;
}
.custom-file-control__btn {
    position: relative;
    display: inline-block;

    & [type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}

/** ------------------------------
    Range
    ------------------------------ */
.custom-range-control {
    display: flex;
    align-items: center;
    margin: var(--form-control-margin) 0;

    & input[type="range"] {
        appearance: none;
        margin: 5px 0;
        width: calc(100% - 50px);

        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 12px;
            border-radius: 6px;
            background-color: var(--brand-light);
            cursor: pointer;
        }

        &::-webkit-slider-thumb {
            appearance: none;
            margin-top: -4px;
            height: 20px;
            width: 20px;
            border-radius: 20px;
            background-color: var(--brand-slate);
            cursor: pointer;
        }

        &:focus {
            outline: none;

            &::-webkit-slider-thumb {
                background-color: var(--brand-blue);
            }
        }
    }
}
.custom-range-control__value {
    flex-grow: 1;
    text-align: center;
}

/** ------------------------------
    Date
    ------------------------------ */
.custom-date-control {
    & input {
        margin-bottom: 3px;
        padding-left: 35px;
        background-image: url("../../img/inline/calendar.svg");
        background-repeat: no-repeat;
        background-position: 10px 50%;
    }
}

/** ------------------------------
    Switch
    ------------------------------ */
.custom-switch-control {
    margin: var(--form-control-margin) 0;
    display: inline-flex;
    align-items: center;

    & input {
        visibility: hidden;
        position: absolute;
        z-index: -1;
    }

    & label {
        display: flex;
        align-items: center;
        font-weight: 300 !important;
        color: var(--brand-slate);
    }

    & .custom-switch-control__toggle {
        display: inline-flex;
        align-items: center;
        padding: 0.25em;
        width: 2.5em;
        height: 1.5em;
        margin-right: 0.5em;
        border-radius: 1.5em;
        background-color: var(--brand-slate);
        border: 0;
        transition: background-color 0.2s ease;

        &::before {
            content: "";
            position: relative;
            top: -1px;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            background-color: #fff;
        }
    }

    /* Checked */
    & input:checked {
        & ~ label > .custom-switch-control__toggle {
            background-color: var(--brand-pink);

            &::before {
                margin-left: auto;
            }
        }
    }

    /* Disabled */
    & input:disabled {
        & ~ label {
            pointer-events: none;

            & > .custom-switch-control__toggle {
                opacity: 0.7;
            }
        }
    }
}

/** ------------------------------
    Skills selection checkboxes
    ------------------------------ */
.custom-select-skills-label {
    margin-bottom: 5px;
}
