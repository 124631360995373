/** ------------------------------
    Base
    ------------------------------ */
.btn {
    display: inline-block;
    padding: 0 var(--btn-padding);
    height: var(--btn-height);
    line-height: var(--btn-height);
    border: 0;
    border-radius: var(--btn-border-radius);
    background-color: var(--btn-default-bg);
    font-family: var(--btn-font-family);
    font-weight: var(--btn-font-weight);
    color: var(--btn-default-color);
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;

    &:focus {
        outline: 0;
    }

    &:hover {
        opacity: 0.9;
    }

    &:matches(:disabled, .disabled) {
        opacity: 0.7;
        pointer-events: none;
    }
}
.btn__icon {
    fill: currentcolor;
    display: inline-block;
    margin-top: -1px;
    margin-right: 4px;
    max-width: none;
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

/** ------------------------------
    Groups
    ------------------------------ */
.btn-group {
    display: inline-flex;

    &:not(.btn-group--justify) > :not(:first-child) {
        margin-left: 10px;
    }
}

.btn-group--justify {
    width: 100%;
    justify-content: space-between;
}

/** ------------------------------
    Modifiers
    ------------------------------ */
.btn--lg {
    height: calc(var(--btn-height) * 1.125);
    line-height: calc(var(--btn-height) * 1.125);
    font-size: 1.125rem;
    font-weight: 400;
}

.btn--block {
    width: 100%;
}

.btn--ghost {
    border: 1px solid currentcolor;
    background-color: transparent;
    color: inherit;

    &:matches(:focus, :hover) {
        color: var(--brand-pink);
        opacity: 1;
    }
}

.btn--link,
.btn--muted {
    height: auto;
    background-color: transparent;
    color: var(--brand-grey);
    font-weight: 400;

    &:matches(:focus, :hover) {
        color: var(--brand-blue);
    }
}

.btn--link {
    padding: 0;
}
