/** ------------------------------
    Header
    ------------------------------ */
@custom-media --menu-collapsed (width < 768px);
@custom-media --menu-expanded (width >= 768px);

.site-header {
    border-bottom: 1px solid #f1f1f1;
}
.site-header__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 2vw;
}

.site-header__menu {
    flex-grow: 1;
}
.site-header__menu-toggle {
    appearance: none;
    margin-left: auto;
    border: 0;
    background-color: transparent;
    color: #fff;
    text-transform: uppercase;

    & > svg {
        margin-left: 0.5em;
    }
}

.site-header__nav {
    & a {
        color: currentcolor;
        text-decoration: none;
    }
}

.site-header__actions {
    display: flex;
    align-items: center;
    margin-left: auto;

    & > :not(:last-child) {
        margin-right: 10px;
    }
}

@media (--menu-collapsed) {
    .site-header__menu {
        display: none;
        margin: 0 -2vw;
        width: 100%;
        background-color: var(--brand-slate);

        &:matches(.is-expanded) {
            display: block;
        }
    }
    .site-header__nav {
        & a {
            display: block;
            padding: 15px;
        }
    }

    .site-header__row:not(:first-child) {
        /* display: none; */
    }

    .site-header__user-welcome {
        display: none;
    }

    .site-header__actions a {
        display: none;
    }

    .site-header__row {
        padding: 10px 10px;
    }

    .site-header__actions {
        margin-right: 0;
    }

    .card__actions {
        width: 100%;
    }
}

@media (--menu-expanded) {
    .site-header__logo {
        margin-right: 3vw;
    }

    .site-header__menu-toggle {
        display: none;
    }

    .site-header__nav {
        display: flex;

        & a:hover {
            color: var(--brand-pink);
        }
    }
    .site-header__nav-item {
        &:not(:first-child) {
            margin-left: 2vw;
        }

        &:last-child {
            margin-left: auto;
        }
    }
}

@media only screen and (max-width: 480px) {
    .site-header__actions {
        margin-left: 10px;
        width: 100%;
    }

    .site-header__actions {
        margin-left: 10px;
        width: 100%;
    }

    .site-header__actions .card__actions button {
        width: 100%;
    }

    .site-header__actions .card__actions {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
