/** ------------------------------
    Login
    ------------------------------ */
.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @mixin container-gutters;
    padding: 80px 0;
    min-height: 100vh;
    background-color: var(--brand-blue);
    }
    .login__body {
        margin: 40px 0;
        width: 100%;
        max-width: 500px;
        }
