/** ------------------------------
    Base
    ------------------------------ */
@define-mixin form-control {
    display: block;
    width: 100%;
    margin: var(--form-control-margin) 0;
    padding: 0 var(--form-control-padding);
    height: var(--form-control-height);
    line-height: var(--form-control-height);
    border: var(--form-control-border-width) solid var(--form-control-default-border-color);
    border-radius: var(--form-control-border-radius);
    background-color: var(--form-control-default-bg);
    font-family: var(--btn-font-family);
    @mixin font-scale 13;
    color: var(--form-control-default-color);
    appearance: none; /* Remove iOS border-radius */
}

@define-mixin form-label {
    display: inline-block;
    font-size: 1rem;
    color: var(--brand-slate);
}

.form {
    }
    .form__group {
        @mixin base-margin;

        & > :last-child {
            margin-bottom: 0;
            }

        & > label {
            @mixin form-label;
            }
        }
    .form__label {
        @mixin form-label;
        }
    .form__control {
        @mixin form-control;

        &:focus {
            outline: none;
            border-color: var(--form-control-default-border-color-active);
            }

        /* Fix padding of multi-line fields */
        &:matches(textarea, select[multiple]) {
            padding: var(--form-control-padding);
            height: auto;
            line-height: var(--base-line-height);
            }

        &:matches(textarea) {
            min-height: 6rem;
            }

        /* Colour pickers don't need to be 100% */
        &[type="color"] {
            width: calc(var(--form-control-height) + 1rem);
            }

        /* Apply cursor for readyonly fields */
        &[readonly] {
            cursor: not-allowed;
            }
        }


/** ------------------------------
    Autocomplete
    ------------------------------ */
.autocomplete {
    position: relative;
    }
    .autocomplete__control {
        & > input {
            margin-bottom: 0;
            padding: 0 0 0 35px !important;
            background: url('../../img/inline/search.svg') no-repeat 10px 50% !important;
            }
        }
    .autocomplete__results {
        position: absolute;
        overflow-y: auto;
        top: calc(100% - var(--form-control-border-width));
        width: 100%;
        max-height: 320px !important;
        border-bottom-left-radius: var(--form-control-border-radius);
        border-bottom-right-radius: var(--form-control-border-radius);
        border: var(--form-control-border-width) solid var(--form-control-default-border-color);
        border-top: 0;
        background-color: #fff;
        line-height: 40px;
        z-index: 5;

        & ul {
            @mixin list-reset;
            padding: 0 !important;
            }
        & li {
            margin-bottom: 0 !important;
            padding: 0 1em;

            &:hover {
                background-color: var(--brand-light);
                cursor: pointer;
                }
            }
        }

/** ------------------------------
    Errors
    ------------------------------ */
.errors {
    margin-bottom: 1em;
    padding: 1em;
    border-radius: var(--form-control-border-radius);
    background: #ffdbe1;
    color: #da0808;
    }
    .errors__list {
        margin: 0;
        padding-left: 1em;
        }
